<template>
  <v-row class="mx-5 mt-5"> 
    <v-col cols=12>
      <v-row>
        <v-col
          cols="12"
          md="4"
          offset-md="4"
        >
          <v-form ref="form" lazy-validation v-model="valid">
            <v-card flat>
              <v-card-text class="mb-0 pb-0">
                <p class="text-h6">Hello there.</p>
                <p class="mt-n3"> Please sign up to continue </p>
              </v-card-text>
              <v-card-text>
                <v-alert
                :color="actionClass"
                dismissible
                v-if="message"
                dark
                >
                {{message}}
                </v-alert>
                  <v-text-field
                  label="Enter Email"
                  outlined
                  placeholder="name@gmail.com"
                  class=""
                  v-model="email"
                  :error-messages="error.email"
                  :rules= "[v => !!v || 'email is required']"
                  >
                  </v-text-field>
                  <v-text-field
                  label="Enter Username"
                  outlined
                  placeholder="Enter username"
                  class=""
                  v-model="username"
                  :error-messages="error.username"
                  :rules= "[v => !!v || 'Username is required']"
                  >
                  </v-text-field>
                  <v-text-field
                  label="Enter Password"
                  outlined
                  password="true"
                  placeholder="password"
                  type="password"
                  class=""
                  v-model="password"
                  :error-messages="error.password"
                  :rules= "[v => !!v || 'Password is required']"
                  >
                  </v-text-field>
                  <v-btn type="submit" :color="sendyMidnightBlue20" dark shaped block larg @click.prevent="submit" :loading="loading"> Sign Up</v-btn>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text depressed :color="sendyAzureTransporter30" @click="$router.push('/login')">Sign in?</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions , mapMutations, mapGetters } from 'vuex';
import colorMxn from '@/mixins/color_mixins';

export default {
  name: "signup",
  mixins: [colorMxn],
  data() {
    return {
      email: '',
      username: '',
      password: '',
      error: {},
      loading: false,
      valid: true,
      message: "",
      actionClass: "success"
    }
  },
  computed: {
    ...mapGetters(['getErrors'])
  },
  watch: {
    getErrors(val) {
      this.error = val;
    }
  },
  methods: {
    ...mapMutations(['setAccessToken', 'setRefreshToken', 'setSession']),
    ...mapActions(['performPostActions']),
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      const payload = {
        email: this.email,
        username: this.username,
        password: this.password,
      };

      const fullPayload = {
        params: payload,
        endpoint: '/auth/register'
      };

      const response = await this.performPostActions(fullPayload);
      console.log(response);
      if(!response) {
        this.actionClass = 'error';
        this.message = 'Registaration Failed';
        this.loading = false;
        return;
      }
      this.message = response.message;
      this.actionClass = response.status === 'success' ? 'success' : 'error';
      this.loading = false;
      if( response.status === "success") {
        setTimeout(() => { 
          this.$router.push('/login');
        }, 2000)
      }
    }
  }
}
</script>
